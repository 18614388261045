<template>
  <div id="contact" class="white--text">
    <Banner bannerhead="Contatti" bannertext=" " bannerimage="contact.jpg" />
    <v-row class="mt-10 text-center">
      <v-col cols="12" md="10" offset-md="1" class="my-15">
        <p>
          email <br />
          <span class="font-weight-bold"
            ><a
              href="mailto:ali.tomassini@gmail.com"
              style="color: white; text-decoration: none"
              >ali.tomassini@gmail.com</a
            >
          </span>
        </p>
      </v-col>
    </v-row>
    <SubscribeMailchimp />
  </div>
</template>

<script>
export default {
  name: "Watch",
  title: "Watch",

  components: {
    SubscribeMailchimp: () => import("@/components/misc/SubscribeMailchimp"),
    Banner: () => import("@/components/Banner"),
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
};
</script>

<style>
</style>