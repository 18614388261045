<template>
  <div id="watch" class="white--text">
    <v-row class="mt-10">
      <v-col cols="12" md="4" offset-md="1">
        <v-carousel cycle hide-delimiters show-arrows-on-hover interval="3000">
          <v-carousel-item>
            <v-img
              :src="require('@/assets/locandina_fabio.jpg')"
              contain
              class="pa-0 ma-0"
              aspect-ratio="0.8"
            ></v-img>
          </v-carousel-item>
          <v-carousel-item>
            <v-img
              :src="require('@/assets/locandina_cristina.jpg')"
              contain
              class="pa-0 ma-0"
              aspect-ratio="0.8"
            ></v-img>
          </v-carousel-item>
          <v-carousel-item>
            <v-img
              :src="require('@/assets/locandina_dorina.jpg')"
              contain
              class="pa-0 ma-0"
              aspect-ratio="0.8"
            ></v-img>
          </v-carousel-item>
        </v-carousel>
        <div>
          <div class="mt-10"></div>
          <p>Dal 14 gennaio 2023 in esclusiva su RaiPlay</p>
          <v-card class="d-flex mt-6" color="black" flat>
            <a href="https://www.raiplay.it/ricerca.html?q=dear+mama" target="_blank">
              <v-img
                alt="Vuetify Name"
                class="shrink"
                contain
                min-width="20"
                max-width="300"
                :src="require('@/assets/RaiPlay_logo.png')"
              />
            </a>
          </v-card>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <p>documentario - 56' - 2021</p>
        <p>
          una produzione <br />
          <span class="font-weight-bold">Societá Italiana di Pediatria </span>
        </p>
        <p>
          scritto e diretto da <br />
          <span class="font-weight-bold">Alice Tomassini </span>
        </p>
        <p>
          con <br />
          <span class="font-weight-bold"
            >Cristina <br />Dorina <br />Fabio
          </span>
        </p>
        <p>
          da un'idea di <br />
          <span class="font-weight-bold">Pietro Ferrara </span>
        </p>
        <p>
          montaggio <br />
          <span class="font-weight-bold">Chiara Russo </span>
        </p>
        <p>
          musiche originali di <br />
          <span class="font-weight-bold">Andrea Guerra </span>
        </p>
        <p>
          fotografia <br />
          <span class="font-weight-bold"
            >Sandro Chessa <br />
            Francesca Zonars <br />
            Sergio Ravoni
          </span>
        </p>
        <p>
          musiche composte e realizzate da <br />
          <span class="font-weight-bold"
            >Andrea Guerra <br />
            Luca Salvadori
          </span>
        </p>
        <p>
          suono in presa diretta <br />
          <span class="font-weight-bold"
            >Gianluca Scarlata <br />
            Andrea Guzzo
          </span>
        </p>
        <p>
          audio post production <br />
          <span class="font-weight-bold">Verdiana Saint Amour </span>
        </p>
        <p>
          grafica e color <br />
          <span class="font-weight-bold">Mauro Vicentini </span>
        </p>
        <p>
          software Engineer <br />
          <span class="font-weight-bold">Vito Margiotta </span>
        </p>
      </v-col>
    </v-row>
    <SubscribeMailchimp />
  </div>
</template>

<script>
export default {
  name: "Watch",
  title: "Watch",

  components: {
    SubscribeMailchimp: () => import("@/components/misc/SubscribeMailchimp"),
  },
  data() {
    return {
      locandine: ["locandina_fabio", "locandina_cristina", "locandina_dorina"],
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4",
      ],
      slides: ["First", "Second", "Third", "Fourth", "Fifth"],
    };
  },
  computed: {},
  mounted() {},
};
</script>

<style>
</style>